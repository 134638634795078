.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog-content {
  background: #0079EA;
  border-radius: 1rem;
  max-width: 500px;
  min-height: fit-content;
  width: 90%;
  padding: 2rem;
  text-align: center;
  position: relative; /* Add this line */
  overflow: visible;
  animation: fadeInScale 0.5s ease-in-out;
}

@keyframes fadeInScale {
  from {
      opacity: 0;
      transform: scale(0.75);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

.modal-icon {
  background-color: #1976d2; /* Replace with your primary color */
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 6rem;
  height: 6rem;
  margin: 0 auto 1rem auto;
  position: absolute;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon img {
  width: 5rem;
}

.modal-title {
  margin-top: 3rem; /* Adjust based on icon size */
  font-size: 1.25rem;
  font-weight: 600;
}

.modal-message {
  margin-bottom: 1.5rem;
  color: #f50057; /* Replace with your secondary color */
  font-size: 1rem;
}

.modal-button {
  background-color: #1976d2; /* Replace with your primary color */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #115293; /* Darker shade of your primary color */
}

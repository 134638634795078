.container {
  margin-top: 20px;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.avatar {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.user-info {
  margin-left: 2px;
}

.sidebar {
  padding: 10px;
}
.tabs {
  width: 100%;
}
.main-content {
  padding: 20px;
}

.category-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
}
.category-button {
  width: 100%;
}
.service-list {
  margin-top: 20px;
  width: 100%;
}

.service-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
}

.service-item:last-child {
  border-bottom: none;
}

/* ser */

.service-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  background-color: #fff;
  margin: 10px 0;
}

.service-card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.service-card-title {
  font-weight: bold;
}

.service-card-icon {
  width: 40px;
  height: 40px;
}

.search__input::-webkit-outer-spin-button,
.search__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search__input[type=number] {
  -moz-appearance: textfield;
}
.search {
    display: inline-flex;
    align-items: center;
    color: #fff;
    padding: 10px 10px;
    border-radius: 50px;
    border: 1px solid transparent; /* Initially transparent */
    transition: 1s linear;
  }
  
  .search.focused {
    border-color: #ffffff; /* Border color on focus */
  }
  
  .search :is(input, button) {
    background: transparent;
    color: inherit;
    border: none;
    outline: none;
  }
  
  .search__input {
    width: 0;
    transition: width 0.5s;
  }
  
  .search__input.focused {
    width: 200px;
    padding-left: 10px;
  }
  
  .search__button {
    display: grid;
    place-items: center;
    width: 35px;
    height: 20px;
    cursor: pointer;
    transition: color 0.25s;
  }
  
  .search__button:hover {
    color: #e3e3e3;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50px;
  }
  
  ::placeholder {
    font: inherit;
    color: #e3e3e3;
  }
  
  i {
    font-size: 1.22em;
  }
  

.react-ios-time-picker-container {
  background-color: aliceblue;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
}
.react-ios-time-picker-btn-container{
  background-color: aliceblue;
  color: black;
}
.react-ios-time-picker-btn {
  color: black;
}

.react-ios-time-picker-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-size: 16px;
  color: black;
}

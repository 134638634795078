a {
  text-decoration: none !important;
}

.category-carousel-container {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  padding: 0 10px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.vendor-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer !important;
}
.custom-border {
  border: none !important;
}
.custom-border:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
.slick-prev,
.slick-next {
  z-index: 2;
  font-size: 2rem;
  color: black !important;
}

.slick-prev {
  left: -25px;
}

.slick-next {
  right: -25px;
}

.slick-slider {
  width: 100%;
}

.vendor-card .MuiPaper-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.advertiesment-slider-container {
  margin: 20px auto;
  max-width: 1200px !important;
}

.slide-item {
  margin: 0 10px;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.video-item {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 24rem;
  align-self: center;
}
.ad-image {
  width: 100%;
  height: auto;
  max-width: 24rem;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}
.gm-style-iw button[title="Close"] {
  display: none !important;
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 4px !important;
}


.css-dqr9h-MuiRating-label label {
  top: 40px !important;  /* Move label down */
  position: relative;     /* Ensure it can be positioned */
}

* {
  font-family: "poppins";
}
input {
  cursor: auto !important; /* or default, depending on your requirement */
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #ffffff;
  color: #8943c4;
  text-align: center;

  font-size: 12px;
  height: 20px;
  width: 135px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 8px;
  left: -140px;
  opacity: 0;
  border-radius: 20px;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  width: 40px; /* Adjust size as necessary */
  height: 40px; /* Adjust size as necessary */
  cursor: pointer;
}

/* Catagory css start */
.sub-category-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.sub-category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(25% - 20px); /* Adjust width for four items per row with gap */
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sub-category-card:hover {
  background-color: #f0f8ff;
  border-color: #0079ea;
}

.sub-category-card img {
  height: 50px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.sub-category-card:hover img {
  transform: scale(1.1); /* Zoom effect on hover */
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .givenMargin {
    margin-left: 50px;
  }
}

.custom-modal .modal-content {
  border-radius: 20px; /* Adjust this value to increase the border radius */
}

.modal-header .btn-close {
  border: #000000;
}

.CustomInputClass .form-control {
  font-size: 24px;
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-arrows {
  -moz-appearance: textfield;
}

.button1 {
  background-color: #0079ea;
  color: white;
  border: none;
  border-radius: 25px; /* Makes the button oval-shaped */
  padding: 10px 20px;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  width: 150px;
  height: 40px;
  text-align: center;
  transition: background-color 0.3s, padding-right 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button1:hover {
  background-color: #0079ead7;
}

.button1:hover span {
  transform: translateX(-10px); /* Shifts text to the left */
}

.button1 span {
  display: inline-block;
  transition: transform 0.3s;
}

.button1::after {
  content: "";
  position: absolute;
  right: 10px; /* Initially place ">" out of view */
  top: 50%;
  transform: translateY(-50%);
  transition: right 0.3s;
}

.button1:hover::after {
  content: ">";
  position: absolute;
  right: 20px; /* Adjust this value as needed */
  transform: translateY(-50%);
}

.buttonForSubmit {
  background-color: #0079ea;
  color: white;
  border: none;
  border-radius: 25px; /* Makes the button oval-shaped */
  padding: 20px 20px;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  width: 250px;
  height: 40px;
  text-align: center;
  transition: background-color 0.3s, padding-right 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonForSubmit:hover {
  background-color: #0079ead7;
}

.buttonForSubmit:hover span {
  transform: translateX(-10px); /* Shifts text to the left */
}

.buttonForSubmit:hover::after {
  content: "";
  position: absolute;
  right: 20px; /* Adjust this value as needed */
  transform: translateY(-50%);
}

.buttonForSubmit span {
  display: inline-block;
  transition: transform 0.3s;
}

.buttonForSubmit::after {
  content: "";
  position: absolute;
  right: 10px; /* Initially place ">" out of view */
  top: 50%;
  transform: translateY(-50%);
  transition: right 0.3s;
}

.button2 {
  background-color: grey; /* Disabled state background color */
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 20px;
  position: relative;
  cursor: not-allowed; /* Indicates the button is disabled */
  width: 150px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .col-lg-8,
  .col-lg-4 {
    flex: 100%;
    max-width: 100%;
  }

  .col-lg-8 {
    margin-bottom: 20px;
  }

  .button1,
  .button2 {
    width: 100%;
  }
}

.JobBroadCastBox {
  position: absolute;
  /* top: 70%; */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  height: fit-content;
  width: 90%;
  z-index: 1;
  border: 1px solid #ccc;
}

/* Media query for large screens (desktops) */
@media (min-width: 1200px) {
  .JobBroadCastBox {
    top: 65%;
    width: 75%;
    padding: 30px;
  }
}

/* Media query for medium screens (small desktops, large tablets) */
@media (min-width: 992px) and (max-width: 1199px) {
  .JobBroadCastBox {
    top: 58%;
    width: 80%;
    padding: 25px;
  }
}

/* Media query for small screens (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  .JobBroadCastBox {
    top: 55%;
    width: 80%;
    padding: 20px;
  }
}

/* Media query for extra small screens (phones) */
@media (max-width: 767px) {
  .JobBroadCastBox {
    top: 65%;
    width: 95%;
    padding: 15px;
  }
}

.professional-services {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}

.icon img {
  width: 50px; /* Adjust size as needed */
  height: 50px;
  margin-right: 10px;
}

.text h2 {
  margin: 0;
  font-size: 1.5rem;
}

.subtext {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.subtext .sub-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  margin-right: 5px;
}

.subtext span {
  font-size: 1rem;
  color: #007bff;
}

.CostomCard {
  background-color: white;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 100%;
  height: auto; /* Ensure the card adjusts its height automatically */
  box-shadow: 0px 4px 15px 0px #0000001a;
}
.CostomCard1 {
  background-color: white;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 100%;
  height: auto; /* Ensure the card adjusts its height automatically */
  box-shadow: 0px 4px 15px 0px #0000001a;
}
.CostomCard1:hover {
  box-shadow: 0px 4px 15px 0px #0000001a;
  cursor: pointer;
  border: #0079ea solid 1px;
}

.CostomCard-content {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.CostomCard-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.CostomCard-description {
  font-size: 22px;
  color: "#444444";
  line-height: "24px";
}

@media (max-width: 400px) {
  .CostomCard-title {
    font-size: 20px;
  }

  .CostomCard-description {
    font-size: 14px;
    color: "#444444";
    line-height: "24px";
  }
}

.service-box {
  background-color: #ffffff; /* Light blue background */
  border-radius: 10px;
  padding: 5px 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-box img {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .CostomCard {
    padding: 10px;
  }
  .service-box {
    padding: 3px 8px;
  }
}

@media (max-width: 576px) {
  .CostomCard {
    margin: 10px 0;
  }
  .service-box {
    margin: 3px;
  }
}

.normalBox {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 8px 12px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.normalBox img {
  margin-right: 5px;
}
.form-control.CostomWidth {
  width: 40%;
}

@media (max-width: 768px) {
  .CostomCard {
    padding: 10px;
  }
  .form-control.CostomWidth {
    width: 60%;
  }
  .normalBox {
    padding: 3px 8px;
  }
}

@media (max-width: 576px) {
  .CostomCard {
    margin: 10px 0;
  }
  .form-control .CostomWidth {
    width: 100%;
  }
  .normalBox {
    margin: 3px;
  }
}

.float-end {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-image {
  border-radius: 5px;
  width: 100px;
  height: 100px;
}

.add-button {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 2px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}
.add-button:hover {
  background-color: #007bff;
  color: #ffffff;
  transition: transform 0.3s;
}

.quantity-control {
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
}

.quantity-control button {
  background-color: #ffffff;
  color: #007bff;
  border: none;
  /* padding: 5px 10px; */
  cursor: pointer;
  font-size: 16px;
}

.quantity-control span {
  /* margin: 0 10px; */
  font-size: 16px;
}

.BorderButton {
  border: "1px solid red";
}

hr.DottedLine {
  border-top: 3px dashed #929090;
}

/* Catagory css End */
.date-time-picker-container {
  width: fit-content;
  border-radius: 10px;
  /* text-align: center; */
}

h3 {
  margin-bottom: 20px;
}

.date-picker-section p,
.time-picker-section p {
  margin-bottom: 10px;
  font-weight: bold;
}

.date-options {
  margin: 20px;
}

.date-option {
  margin: 10px;
  width: 60px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  color: #464141;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.date-option.selected {
  border-color: #0079ea;
  background-color: #f1f8ff;
  color: #0079ea;
}

.date-option div:first-child {
  font-weight: bold;
}

.time-picker-section span {
  font-weight: normal;
  font-size: 12px;
}

.continue-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.continue-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.continue-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.squreButton {
  background-color: #0079ea;
  color: white;
  border: none;
  border-radius: 8px; /* Makes the button oval-shaped */
  padding: 10px 30px;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  width: 150px;
  height: 40px;
  text-align: center;
  transition: background-color 0.3s, padding-right 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.squreButton:hover {
  background-color: #0079ead7;
}

.squreButton:hover span {
  transform: translateX(-10px); /* Shifts text to the left */
}

.squreButton span {
  display: inline-block;
  transition: transform 0.3s;
}

.squreButton::after {
  content: "";
  position: absolute;
  right: 10px; /* Initially place ">" out of view */
  top: 50%;
  transform: translateY(-50%);
  transition: right 0.3s;
}

.squreButton:hover::after {
  content: ">";
  position: absolute;
  right: 20px; /* Adjust this value as needed */
  transform: translateY(-50%);
}

.EditButton {
  background-color: #f1f1f1;
  color: #1e1e1e;
  border: none;
  border-radius: 8px; /* Makes the button oval-shaped */
  padding: 8px 8px;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  width: 80px;
  height: 35px;
  text-align: center;
  transition: background-color 0.3s, padding-right 0.3s, transform 0.3s;

  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 21;
}

/* Add this to your CSS file */

/* Add this to your CSS file */
.custom-modal-body {
  padding: 20px;
  text-align: center;
}

.custom-modal-title {
  margin-bottom: 20px;
  font-weight: bold;
}

.custom-input-group {
  display: flex;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #ddd; /* Add border to the input group */
  border-radius: 6px; /* Rounded corners */
  overflow: hidden; /* Ensures the rounded corners are visible */
}

.custom-form-control {
  flex: 1;
  border: none;
  padding: 10px 15px;
  border-radius: 4px; /* Rounded left corners */
}

.custom-btn {
  border: none;
  padding: 10px 15px;
  border-radius: 4px; /* Rounded right corners */
  cursor: auto;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.custom-btn-primary {
  color: #999; /* Default text color */
}

.custom-btn-primary.enabled {
  color: #1b52e9;
}

.custom-btn-primary.disabled {
  color: #999;
}

.custom-btn-primary:disabled {
  cursor: not-allowed;
}

/* Catagory css */

/* Catagory css */
